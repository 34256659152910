import { AxiosError } from 'axios';
import { QueryClient } from 'react-query';

import { AmplitudeEvent, EVENTS } from '../@types';

/*
  export client so we can make queries on server side
*/
export const client = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      // staleTime: 60 * 1000 * 5
    },
    mutations: {
      onError: (err) => {
        if ('message' in (err as unknown as Error)) {
          console.log('error', (err as Error).message);
        }
      },
    },
  },
});

export const handleQueryError = (logEvent: ({ eventName, params }: AmplitudeEvent) => void, hookName: string) => {
  return (err: unknown) => {
    logEvent({
      eventName: EVENTS.NETWORK_API_ERROR,
      params: {
        hook: hookName,
        errorMessage: (err as AxiosError).message,
        stack: JSON.stringify((err as AxiosError).stack),
      },
    });
  };
};
