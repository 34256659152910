import { STORAGE_KEYS } from '../@types';

export const storage = {
  getToken: (): string => {
    if (typeof window !== 'undefined') return localStorage.getItem(STORAGE_KEYS.TOKEN) ?? '';
    return '';
  },

  setToken: (token: string): void => {
    if (typeof window !== 'undefined') {
      localStorage.setItem(STORAGE_KEYS.TOKEN, token);
    }
  },
};
