import { render, RenderResult } from '@testing-library/react';
import { AxiosInstance } from 'axios';
import { ReactElement, ReactNode } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

import { AppContext } from 'src/context';
import { IAppContext } from '../@types';

const mockContext: IAppContext = {
  robotId: '',
  selectedDelivery: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setRobotId: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSelectedDelivery: () => {},
  lidOpen: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setLidOpen: () => {},
  deliveriesApi: {} as AxiosInstance,
  operationsApi: {} as AxiosInstance,
  privacyApi: {} as AxiosInstance,
  canRedirect: true,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCanRedirect: () => {},
  canReload: true,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCanReload: () => {},
  partnerIds: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setPartnerIds: () => {},
};

const createTestQueryClient = () =>
  new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
      },
    },
  });

// for testing components
export const renderWithClient = (component: ReactElement, state?: Partial<IAppContext>): RenderResult => {
  const context = {
    ...mockContext,
    ...state,
  };
  const testQueryClient = createTestQueryClient();
  const { rerender, ...result } = render(
    <QueryClientProvider client={testQueryClient}>
      <AppContext.Provider value={context}>{component}</AppContext.Provider>
    </QueryClientProvider>
  );
  return {
    ...result,
    rerender: (component: React.ReactElement) =>
      rerender(<QueryClientProvider client={testQueryClient}>{component}</QueryClientProvider>),
  };
};

// for testing custom hooks
export const createWrapper = () => {
  const testQueryClient = createTestQueryClient();
  // eslint-disable-next-line react/display-name
  return ({ children }: { children: ReactNode }) => (
    <QueryClientProvider client={testQueryClient}>{children}</QueryClientProvider>
  );
};
