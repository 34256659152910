import { ClipLoader } from 'react-spinners';

import { styled } from '../../stitches.config';

const Container = styled('div', {
  position: 'absolute',
  top: '50%',
  left: '45%',
  right: '45%',
});

const Loader = () => {
  return (
    <Container data-testid="spinner">
      <ClipLoader size={50} />
    </Container>
  );
};

export default Loader;
