export const AMPLITUDE_API_KEY = process.env.NEXT_PUBLIC_QR_AMPLITUDE_API_KEY as string;

// Auth0
export const AUTH0_DOMAIN = process.env.NEXT_PUBLIC_AUTH0_DOMAIN as string;
export const AUTH0_CLIENT_ID = process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID as string;
export const AUTH0_ORIGIN_URL = process.env.NEXT_PUBLIC_ORIGIN_URL as string;
export const AUTH0_AUDIENCE = process.env.NEXT_PUBLIC_AUTH0_AUDIENCE as string;

export const DELIVERIES_BASE_URL = process.env.NEXT_PUBLIC_DELIVERIES_BASE_URL as string;
export const OPERATIONS_API_ENDPOINT = process.env.NEXT_PUBLIC_OPERATIONS_API_URL as string;
export const ORDERS_API_ENDPOINT = process.env.NEXT_PUBLIC_ORDERS_API_URL as string;
export const INTEGRATIONS_EU_API_ENDPOINT = process.env.NEXT_PUBLIC_INTEGRATIONS_EU_API_URL as string;
export const FULLSTORY_ORG_ID = process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID as string;
export const AWS_ENV = process.env.NEXT_PUBLIC_AWS_ENV as string;
export const LAUNCHDARKLY_SDK_CLIENT_SIDE_ID = process.env.NEXT_PUBLIC_LAUNCHDARKLY_SDK_CLIENT_SIDE_ID as string;
