import React from 'react';

import { styled } from '../../stitches.config';

type Props = {
  children: React.ReactNode;
};

const Container = styled('div', {
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  flexWrap: 'wrap',
  gap: '1rem',
  paddingBottom: '1rem',
});

const ButtonContainer: React.FC<Props> = ({ children }) => {
  return <Container>{children}</Container>;
};

export default ButtonContainer;
