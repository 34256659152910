import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from 'react-query';

import { deliveryQueryKeys } from 'src/@types';
import { useAppContext } from 'src/context';
import { getPartnerInfo } from 'src/services';

export const usePartners = () => {
  const { deliveriesApi, setPartnerIds } = useAppContext();
  const { user } = useAuth0();
  const authId = user?.sub ?? '';

  return useQuery({
    queryKey: deliveryQueryKeys.partners(authId),
    queryFn: () => getPartnerInfo(deliveriesApi, authId),
    enabled: !!authId,
    onSuccess: (data) => {
      setPartnerIds(data.map((partner) => partner.id));
    },
  });
};
