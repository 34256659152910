import { ChevronDownIcon } from '@radix-ui/react-icons';
import { useCallback, useState } from 'react';

import { useOutsideClick } from 'src/hooks';
import { styled } from 'src/stitches.config';

interface Props {
  icon: React.ReactNode;
  selected: DropdownItem;
  options: DropdownItem[];
  onSelect: (option: DropdownItem) => void;
}

export interface DropdownItem {
  value: string;
  label: string;
}

const DropdownButton = styled('button', {
  width: '100px',
  height: '36px',
  backgroundColor: '$gray1',
  border: '1px solid $gray12',
  borderRadius: '0.5rem',
  display: 'flex',
  gap: '0.5rem',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '1rem',
  cursor: 'pointer',
});

const DropdownList = styled('div', {
  position: 'absolute',
});

const DropdownItemContainer = styled('div', {
  position: 'relative',
  top: 0,
  display: 'flex',
  flexDirection: 'column',
  width: '100px',
  borderBottomRightRadius: '0.5rem',
  borderBottomLeftRadius: '0.5rem',
  border: '1px solid $gray12',
});

const DropdownItem = styled('button', {
  height: '36px',
  fontSize: '1rem',
  border: 'none',
  backgroundColor: '$gray1',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '$gray3',
  },
});

export const Dropdown = ({ icon, selected, options, onSelect }: Props) => {
  const [open, setOpen] = useState(false);

  const ref = useOutsideClick(() => setOpen(false));

  const onClickDropdownItem = useCallback(
    (option: DropdownItem) => {
      onSelect(option);
      setOpen(false);
    },
    [onSelect]
  );

  return (
    <div ref={ref}>
      <DropdownButton
        onClick={() => setOpen((prev) => !prev)}
        css={
          open
            ? {
                borderBottomRightRadius: '0',
                borderBottomLeftRadius: '0',
                borderBottom: 'none',
              }
            : {}
        }
      >
        {icon}
        {selected.label}
        <ChevronDownIcon height={18} width={18} />
      </DropdownButton>

      <DropdownList>
        {open && (
          <DropdownItemContainer>
            {options.map((option, index) => (
              <DropdownItem
                key={option.value}
                onClick={() => onClickDropdownItem(option)}
                css={
                  index == options.length - 1
                    ? {
                        borderBottomRightRadius: '0.5rem',
                        borderBottomLeftRadius: '0.5rem',
                      }
                    : {}
                }
              >
                {option.label}
              </DropdownItem>
            ))}
          </DropdownItemContainer>
        )}
      </DropdownList>
    </div>
  );
};
