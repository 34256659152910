import { cyan, gray, indigo, mauve, red, yellow } from '@radix-ui/colors';
import { createStitches } from '@stitches/react';

import { SpaceXYValues } from './@types';

export const { getCssText, createTheme, styled, css, globalCss, keyframes } = createStitches({
  theme: {
    colors: {
      ...mauve,
      ...yellow,
      ...indigo,
      ...red,
      ...cyan,
      ...gray,
      primary: '#141313',
    },
    shadows: {
      default: '0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .06)',
      md: '0 4px 6px -1px rgba(0, 0, 0, .1), 0 2px 4px -1px rgba(0, 0, 0, .06)',
      lg: '0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -2px rgba(0, 0, 0, .05)',
      xl: '0 20px 25px -5px rgba(0, 0, 0, .1), 0 10px 10px -5px rgba(0, 0, 0, .04)',
      '2xl': '0 25px 50px -12px rgba(0, 0, 0, .25)',
      inner: 'inset 0 2px 4px 0 rgba(0,0,0,0.06)',
      outline: '0 0 0 3px rgba(52,144,220,0.5)',
      none: 'none',
    },
    zIndices: {
      auto: 'auto',
      '0': 0,
      '10': 10,
      '20': 20,
      '30': 30,
      '40': 40,
      '50': 50,
    },
    borderWidths: {
      '1': '1px',
      '2': '2px',
      '4': '4px',
      '8': '8px',
    },
    radii: {
      none: '0',
      sm: '2px',
      default: '3px',
      lg: '5px',
      full: '9999px',
    },
    sizes: {
      auto: 'auto',
      px: '1px',
      '1': '0.25rem',
      '2': '0.5rem',
      '3': '0.75rem',
      '4': '1rem',
      '5': '1.25rem',
      '6': '1.5rem',
      '8': '2rem',
      '10': '2.5rem',
      '12': '3rem',
      '16': '4rem',
      '24': '6rem',
      '32': '8rem',
      '48': '12rem',
      '64': '16rem',
      '1/2': '50%',
      '33p': '33.33333%',
      '66p': '66.66667%',
      '25p': '25%',
      '75p': '75%',
      '20p': '20%',
      '40p': '40%',
      '60p': '60%',
      '80p': '80%',
      '16p': '16.66667%',
      '83p': '83.33333%',
      '38p': '38.46154%',
      '61p': '61.53846%',
      '92p': '92%',
      '100p': '100%',
      'w-screen': '100vw',
      'h-screen': '100vh',
    },
    space: {
      auto: 'auto',
      px: '1px',
      '0': '0',
      '1': '0.25rem',
      '2': '0.5rem',
      '3': '0.75rem',
      '4': '1rem',
      '5': '1.25rem',
      '6': '1.5rem',
      '8': '2rem',
      '10': '2.5rem',
      '12': '3rem',
      '16': '4rem',
      '20': '5rem',
      '24': '6rem',
      '32': '8rem',
      '40': '10rem',
    },
    fonts: {
      spartan: 'Spartan',
      sans: 'Lato',
    },
    fontWeights: {
      hairline: 100,
      thin: 200,
      light: 300,
      book: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
      extrabold: 800,
      black: 900,
    },
    fontSizes: {
      '4xs': '.375rem', // 6px
      '3xs': '.5rem', // 8px
      '2xs': '.625rem', // 10px
      xs: '.75rem', // 12px
      sm: '.875rem', // 14px
      base: '1rem', // 16px
      lg: '1.125rem', // 18px
      xl: '1.25rem', // 20px
      '2xl': '1.5rem', // 24px
      '3xl': '1.875rem', // 30px
      '4xl': '2.25rem', // 36px
      '5xl': '3rem', // 48px
      '6xl': '4rem', // 64px
    },
    lineHeights: {
      none: 1,
      tight: 1.25,
      snug: 1.375,
      normal: 1.5,
      relaxed: 1.625,
      loose: 2,
    },
    letterSpacings: {
      condensed: '-.15em',
      tightest: '-.1em',
      tighter: '-.05em',
      tight: '-.025em',
      normal: '0',
      wide: '.025em',
      wider: '.05em',
      widest: '.1em',
      extended: '.15em',
    },
  },
  media: {
    sm: '(min-width:568px)',
    md: '(min-width:768px)',
    lg: '(min-width:1024px)',
    xl: '(min-width:1280px)',
  },
  utils: {
    'space-x': (value: SpaceXYValues) => ({
      'margin-left': value,
    }),
    'space-y': (value: SpaceXYValues) => ({
      'margin-top': value,
    }),
    paddingX: (value: SpaceXYValues) => ({
      paddingLeft: value,
      paddingRight: value,
    }),
    paddingY: (value: SpaceXYValues) => ({
      paddingTop: value,
      paddingBottom: value,
    }),
  },
});
