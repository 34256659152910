import { ExitIcon } from '@radix-ui/react-icons';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { LanguageSwitcher } from 'src/components';
import { COMMON_KEYS, LOC_NS } from 'src/i18n/types';
import logo2 from '../../assets/Coco-gradient.png';
import { useAuth } from '../../hooks';
import { styled } from '../../stitches.config';

const NavContainer = styled('header', {
  width: '100%',
  height: '70px',
  justifyContent: 'space-between',
  alignItems: 'center',
  display: 'flex',
  cursor: 'pointer',
  padding: '20px',
});

const LogoContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  width: '50%',
});

const RightContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
});

const LogoutContainer = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  color: '$gray9',
  height: '100px',
  paddingLeft: '$5',
});

const Logout = styled('p', {
  display: 'flex',
  alignItems: 'center',
  marginLeft: '$2',
  '@media (max-width: 525px)': {
    display: 'none',
  },
});

const StyledLogo = styled('img', {
  width: '120px',
  marginTop: '5px',
});

export const Navbar = () => {
  const { t } = useTranslation(LOC_NS.COMMON);
  const { isAuthenticated, handleLogout } = useAuth();
  const router = useRouter();
  const handleNavigate = useCallback(() => router.push('/'), [router]);

  return (
    <NavContainer>
      <LogoContainer onClick={handleNavigate}>
        <StyledLogo src={logo2.src} alt="coco logo" />
      </LogoContainer>

      <RightContainer>
        <LanguageSwitcher />

        {isAuthenticated && (
          <LogoutContainer onClick={handleLogout}>
            <ExitIcon height={18} width={18} />
            <Logout>{t(COMMON_KEYS.LOG_OUT, 'Log out')}</Logout>
          </LogoutContainer>
        )}
      </RightContainer>
    </NavContainer>
  );
};
