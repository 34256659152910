import { MagnifyingGlassIcon } from '@radix-ui/react-icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { LOC_NS, ORDERS_KEYS } from 'src/i18n/types';
import { styled } from '../../stitches.config';

type Props = {
  searchTerm: string;
  handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const Searchbar: React.FC<Props> = ({ searchTerm, handleSearch }) => {
  const { t } = useTranslation(LOC_NS.ORDERS);

  return (
    <Container>
      <SearchContainer>
        <MagnifyingGlassIcon
          style={{
            height: 22,
            width: 25,
          }}
        />
        <SearchInput
          value={searchTerm}
          onChange={handleSearch}
          placeholder={t(ORDERS_KEYS.SEARCH_FOR_AN_ORDER, 'Search for an order')}
        />
      </SearchContainer>
    </Container>
  );
};

export default Searchbar;

const Container = styled('div', {
  background: '#fff',
  borderRadius: '60px',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: '$5',
  maxWidth: '800px',
  width: '100%',
});

const SearchContainer = styled('div', {
  display: 'flex',
  border: '1px solid black',
  borderRadius: '30px',
  padding: '$3 $6',
  alignItems: 'center',
});

const SearchInput = styled('input', {
  height: '$6',
  width: ' 100%',
  outline: 'none',
  borderRadius: 'inherit',
  padding: '0 60px 0 20px',
  fontSize: '$5',
  border: 'none',
});
