export const orderQueryKeys = {
  all: ['orders'] as const,
  lists: () => [...orderQueryKeys.all, 'list'] as const,
  list: (filters: unknown) => [...orderQueryKeys.lists(), filters] as const,
  details: () => [...orderQueryKeys.all, 'detail'] as const,
  detail: (id: string | null) => [...orderQueryKeys.details(), id] as const,
};

export interface OrderDetails {
  id: string;
  incomingOrderId: string;
  integrationPartnerId: string | null;
  customerId: string | null;
  cartId: string | null;
  trackId: string | null;
  note: string | null;
  internalNote: string | null;
  validityStatus: string;
  distance?: number;
  scheduledAt: Date | null;
  createdAt: string;
  updatedAt: string;
  customer: Customer | undefined;
  cart: Cart | null;
  integrationPartner: IntegrationPartner | null;
  statuses: OrderStatusHistory[];
  incomingOrder: IncomingOrder;
  parseStatus: ParseType;
  restrictedOrder: boolean;
  isTest: boolean;
  errors: OrderError[];
  orderType: string;
  originalIntegrationName: string;
  lastStatus: OrderStatusHistory;
}

export interface Customer {
  id: string;
  name: string;
  phone?: string | null;
  locationId?: string;
  location?: Location;
  createdAt: string;
}

export interface Location {
  id: string;
  name: string | null;
  details: string | null;
  latitude: number | null;
  longitude: number | null;
  streetLine1: string;
  streetLine2: string;
  zip: string | null;
  state: string | null;
  city: string | null;
  unit?: string | null;
  raw: string;
  createdAt: string;
  original?: string;
  originalType?: string;
}

export enum ValidityStatus {
  VALID = 'VALID',
  INVALID = 'INVALID',
  ACCEPTABLE = 'ACCEPTABLE',
}

export interface Cart {
  id: string;
  total: number;
  subtotal: number;
  tax?: number;
  tip?: number;
  deliveryFee?: number | null;
  createdAt: string;
  items: Array<OrderItem>;
}

export interface OrderItem {
  id: string;
  cartId: string;
  externalId: string;
  quantity: number;
  price: number;
  title?: string;
  description?: string | null;
  createdAt: string;
  unitPrice?: number;
  serialNumber?: number;
  modifiers?: Array<unknown>;
}

export interface IntegrationPartner {
  id: string;
  integrationId: string;
  partnerId: string;
  source: string;
  externalId: string;
  name?: string | null;
  createdAt: string;
  updatedAt: string;
  deletedAt?: Date | null;
  integration: Integration;
  partner: PartnerType;
}

export interface PartnerType {
  id: string;
  hubId: string;
  cityId: string;
  originalId: string;
  systemName: string;
  humanReadableName?: string;
  phone: string;
  locationId: string;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string | null;
  location: Location;
}

export interface Integration {
  id: string;
  name: string;
  slug: string;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string | null;
}

export enum StatusHistoryStatus {
  RECEIVED = 'RECEIVED',
}

export enum StatusHistoryResult {
  SUCCEED = 'SUCCEED',
}

export interface OrderStatusHistory {
  id: string;
  orderId: string;
  status: StatusHistoryStatus;
  operationResult: StatusHistoryResult;
  createdAt: string;
}

export interface IncomingOrder {
  id: string;
  relatedOrderId: null;
  incomingPayload: string;
  createdAt: string;
}

export enum ParseType {
  AUTO_PARSED = 'AUTO_PARSED',
  MANUALLY_PARSED = 'MANUALLY_PARSED',
  REORDER = 'REORDER',
}

export interface OrderError {
  message: string;
}
