import { useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { OnResultFunction, QrReader } from 'react-qr-reader';
import { toast } from 'react-toastify';

import { EVENTS } from 'src/@types';
import { useAppContext } from 'src/context';
import { useAmplitude } from 'src/hooks';
import { LOC_NS, ORDERS_KEYS } from 'src/i18n/types';
import { styled } from 'src/stitches.config';
import { Header } from '../Header';
import { ScannerViewFinder } from '../ScannerViewFinder';

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: '$24',
});

const ScannerContainer = styled('div', {
  margin: 'auto',
  width: '100%',
  padding: '$6',
});

const Scan: React.FC = () => {
  const { t } = useTranslation(LOC_NS.ORDERS);
  const router = useRouter();
  const { logEvent } = useAmplitude();
  const { setRobotId, setLidOpen, robotId } = useAppContext();
  const searchParams = useSearchParams();

  useEffect(() => {
    if (!robotId) {
      logEvent({ eventName: EVENTS.SCANNER_OPEN });
    }
  }, [logEvent, robotId]);

  const handleScan: OnResultFunction = useCallback(
    (result, error) => {
      if (result) {
        const url = result.getText();
        const protocol = 'https://';

        if (!url.startsWith(protocol)) {
          logEvent({
            eventName: EVENTS.SCANNER_SCAN_ERROR,
            params: {
              errorMessage: `Non-url scan: ${url}`,
            },
          });
          return false;
        }

        const urlParts: Array<string> = url.slice(protocol.length).split('/');

        const serialNo = urlParts.find((str: string) => str.toLowerCase().startsWith('c1'));

        const orderId = searchParams.get('orderId');
        if (serialNo && orderId) {
          // selectedDelivery is populated if the user is redirected here to scan another Coco
          return router.push({
            pathname: `/orders/${orderId}`,
            search: `?cocoId=${serialNo}`,
          });
        }
        if (serialNo) {
          setRobotId(serialNo);

          return router.push({
            pathname: '/orders',
            search: `?cocoId=${serialNo}`,
          });
        }

        toast.error(t(ORDERS_KEYS.UNABLE_TO_SCAN_QR, 'Unable to scan QR code'));
        logEvent({
          eventName: EVENTS.SCANNER_SCAN_ERROR,
          params: {
            errorMessage: `Invalid scan: ${url}`,
          },
        });
      }

      if (error?.message) {
        if (error.message === 'Permission denied') {
          toast.error(t(ORDERS_KEYS.ENABLE_CAMERA_PERMISSIONS, 'Please enable camera permissions'));
        }
        logEvent({
          eventName: EVENTS.SCANNER_SCAN_ERROR,
          params: {
            errorMessage: error.message,
          },
        });
      }
    },
    [logEvent, router, searchParams, setRobotId, t]
  );

  useEffect(() => {
    // reset cached robot when user navigates back to scanner screen
    setRobotId('');

    // scanning a new robot should allow the lid opening animation to appear
    setLidOpen(false);
  }, [setLidOpen, setRobotId]);

  return (
    <Container>
      <Header>{t(ORDERS_KEYS.SCAN_QR_CODE, "Scan a Coco's QR code")}</Header>
      <ScannerContainer>
        <QrReader
          ViewFinder={() => <ScannerViewFinder />}
          constraints={{
            facingMode: 'environment',
          }}
          onResult={handleScan}
        />
      </ScannerContainer>
    </Container>
  );
};

export default Scan;
