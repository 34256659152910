import { DefaultOptions } from 'react-query';

import { CommonRefetchIntervals } from '../@types/react-query';

export const queryConfig: DefaultOptions = {
  queries: {
    useErrorBoundary: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: false,
  },
};

const MILLISECOND = 1000;
export const CommonIntervalsMap: { [k in CommonRefetchIntervals]: number } = {
  '5 seconds': MILLISECOND * 5,
  '10 seconds': MILLISECOND * 10,
  '20 seconds': MILLISECOND * 20,
  '30 seconds': MILLISECOND * 30,
  '1 minute': MILLISECOND * 60,
  '5 minutes': MILLISECOND * 60 * 5,
  '10 minutes': MILLISECOND * 60 * 10,
  '15 minutes': MILLISECOND * 60 * 15,
};
