import { AxiosInstance } from 'axios';

import { Delivery } from 'src/@types';
import { getPII } from 'src/services';

export const populateDeliveriesWithPII = async (
  deliveries: Delivery[],
  privacyApi: AxiosInstance
): Promise<Delivery[]> => {
  return Promise.all(
    deliveries.map(async (delivery: Delivery) => {
      if (delivery.customerId && delivery.customerName === 'REDACTED') {
        try {
          const PII = await getPII(privacyApi, delivery.customerId);
          if (!!PII?.name) {
            return {
              ...delivery,
              customerName: PII.name,
            };
          }
        } catch (err) {
          // should be unreachable
          console.log(err);
        }
      }
      return delivery;
    })
  );
};
