import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { ReactNode, useMemo } from 'react';

import { UserRole } from 'src/@types';
import { Loader, Unauthorized } from 'src/components';
import { AUTH0_ROLE_CLAIMS_KEY } from 'src/constants';

interface Props {
  children: ReactNode;
}

const AccessProvider = ({ children }: Props) => {
  const { user, isAuthenticated, isLoading } = useAuth0();

  const isAuthorized = useMemo(() => {
    if (
      isAuthenticated &&
      user &&
      user[AUTH0_ROLE_CLAIMS_KEY] &&
      Array.isArray(user[AUTH0_ROLE_CLAIMS_KEY]) &&
      user[AUTH0_ROLE_CLAIMS_KEY].includes(UserRole.Merchants)
    ) {
      return true;
    }
    return false;
  }, [isAuthenticated, user]);

  if (isLoading) {
    return <Loader />;
  }

  if (!isAuthorized) {
    return <Unauthorized />;
  }

  return <>{children}</>;
};

export default withAuthenticationRequired(AccessProvider);
