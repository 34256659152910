import { FullStory } from '@fullstory/browser';
import { GlobeIcon } from '@radix-ui/react-icons';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FS_EVENTS } from 'src/@types';
import { Dropdown, DropdownItem } from './Dropdown';

export const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const onSelectLanguage = useCallback(
    (option: DropdownItem) => {
      const newLanguage = option.value;
      i18n.changeLanguage(newLanguage);
      FullStory('trackEvent', {
        name: FS_EVENTS.LANGUAGE_CHANGE,
        properties: {
          newLanguage,
        },
      });
    },
    [i18n]
  );

  const selectedLanguage = useMemo(
    () => ({
      value: i18n.language,
      label: i18n.language,
    }),
    [i18n.language]
  );

  const options = useMemo(() => {
    let languages = i18n.options.supportedLngs;
    languages = !!languages ? languages.filter((language) => language !== 'cimode') : undefined;

    return !!languages
      ? languages.map((language) => ({
          value: language,
          label: language,
        }))
      : [];
  }, [i18n]);

  return (
    <Dropdown
      icon={<GlobeIcon height={16} width={16} />}
      selected={selectedLanguage}
      options={options}
      onSelect={onSelectLanguage}
    />
  );
};
