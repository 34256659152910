import { useTranslation } from 'react-i18next';

import { Button } from 'src/components';
import { useAuth } from 'src/hooks';
import { COMMON_KEYS, LOC_NS } from 'src/i18n/types';
import { styled } from 'src/stitches.config';

const Container = styled('div', {
  height: '100vh',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const Header = styled('div', {
  fontSize: '20px',
  marginBottom: '20px',
  fontWeight: '600',
});

const Copy = styled('div', {
  fontSize: '16px',
  marginBottom: '40px',
});

const ButtonContainer = styled('div', {
  width: '300px',
});

export const Unauthorized = () => {
  const { t } = useTranslation(LOC_NS.COMMON);
  const { handleLogout } = useAuth();

  return (
    <Container>
      <Header>{t(COMMON_KEYS.UNAUTHORIZED, 'Unauthorized')}</Header>
      <Copy>{t(COMMON_KEYS.MISSING_PERMISSIONS, 'You do not have the required permissions to view this page.')}</Copy>

      <ButtonContainer>
        <Button onClick={handleLogout}>{t(COMMON_KEYS.LOG_OUT, 'Log out')}</Button>
      </ButtonContainer>
    </Container>
  );
};
