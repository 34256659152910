import { AxiosError, AxiosInstance } from 'axios';

import { populateDeliveriesWithPII } from 'src/utils';
import { AmplitudeEvent, Delivery, DeliveryResponse, EVENTS, LoadRobotResponse, Partner } from '../@types';

export const getPartnerInfo = async (deliveriesApi: AxiosInstance, authId: string): Promise<Array<Partner>> => {
  const { data } = await deliveriesApi.get(`/api/v2/delivery/partners/${authId}`);

  return data;
};

export const getDeliveriesByPartners = async (
  deliveriesApi: AxiosInstance,
  privacyApi: AxiosInstance,
  logEvent: ({ eventName, params }: AmplitudeEvent) => void,
  partnerIds: string[]
): Promise<Array<Delivery>> => {
  const { data } = await deliveriesApi.post<DeliveryResponse | Array<Delivery>>(
    `/api/v2/delivery/active-robot-deliveries-by-partner`,
    {
      partnerIds,
      sortBy: 'asc',
    }
  );

  let deliveries = data.hasOwnProperty('deliveries')
    ? (data as DeliveryResponse).deliveries
    : (data as Array<Delivery>);

  if (deliveries.length === 0) {
    logEvent({
      eventName: EVENTS.DELIVERIES_NO_ORDERS,
      params: {
        merchantIds: partnerIds.join(','),
      },
    });
  } else {
    deliveries = await populateDeliveriesWithPII(deliveries, privacyApi);
  }

  return deliveries;
};

export const loadRobotWithOrder = async (
  deliveriesApi: AxiosInstance,
  serialNo: string,
  attemptId: string
): Promise<{ success: boolean; error: string }> => {
  try {
    const { data } = await deliveriesApi.patch<LoadRobotResponse>(`/api/v2/robot-loaded-hook/${attemptId}`, {
      botSerial: serialNo,
      shouldCloseLid: true,
    });
    const isSuccess = data?.id?.length > 0;
    return isSuccess ? { success: true, error: '' } : { success: false, error: 'Failed to load bot with order' };
  } catch (err) {
    return {
      success: false,
      error: (err as AxiosError<{ message: string }>).response?.data.message as string,
    };
  }
};
