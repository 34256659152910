import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';

import { EVENTS, UserRole } from '../@types';
import { AUTH0_ORIGIN_URL, AUTH0_ROLE_CLAIMS_KEY } from '../constants';
import useAmplitude from './useAmplitude';

const useAuth = () => {
  const { user, logout, ...rest } = useAuth0();
  const { logEvent } = useAmplitude();

  const userRoles: Array<UserRole> = user?.[AUTH0_ROLE_CLAIMS_KEY] ?? [];

  const handleLogout = useCallback(() => {
    logEvent({
      eventName: EVENTS.AUTH_LOGOUT,
      params: {
        datetime: new Date().toISOString(),
        merchantName: user?.name ?? '',
        merchantId: user?.sub ?? '',
      },
    });

    logout({
      logoutParams: {
        returnTo: AUTH0_ORIGIN_URL,
      },
    });
  }, [logEvent, logout, user?.name, user?.sub]);

  return {
    ...rest,
    roles: userRoles,
    user,
    handleLogout,
  };
};

export default useAuth;
