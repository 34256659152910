import { useMutation } from 'react-query';

import { useAppContext } from 'src/context';
import { robotQueryKeys } from '../@types/react-query';
import { loadRobotWithOrder } from '../services';

interface LoadRobotParams {
  serialNo: string;
  attemptId: string;
}

const useLoadRobot = ({ serialNo, attemptId }: LoadRobotParams, config?: Record<string, unknown>) => {
  const { deliveriesApi } = useAppContext();
  return useMutation({
    mutationKey: robotQueryKeys.load(serialNo, attemptId),
    mutationFn: () => loadRobotWithOrder(deliveriesApi, serialNo, attemptId),
    ...config,
  });
};

export default useLoadRobot;
