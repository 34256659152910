import React from 'react';

import { styled } from '../../stitches.config';

type Props = {
  children: React.ReactNode;
};

const StyledHeader = styled('h1', {
  fontWeight: '$bold',
  fontSize: '18px',
  lineHeight: '20px',
  letterSpacing: '0.5px',
  color: '$gray12',
  paddingY: '1.25rem',
  textAlign: 'center',
  flex: 1,
});

const Header: React.FC<Props> = ({ children }) => {
  return <StyledHeader>{children}</StyledHeader>;
};

export default Header;
