import { useQuery, UseQueryOptions } from 'react-query';

import { operationsQueryKeys } from 'src/@types';
import { useAppContext } from 'src/context';
import { getLoadableRobots } from 'src/services';
import { usePersistStore } from 'src/stores';
import useAmplitude from './useAmplitude';

interface Props {
  config: UseQueryOptions<string[], Error, string[]>;
}

export const useLoadableRobots = ({ config }: Props) => {
  const { logEvent } = useAmplitude();
  const { operationsApi, deliveriesApi, partnerIds } = useAppContext();
  const { robotsAttemptedToOpen } = usePersistStore();

  return useQuery({
    queryKey: operationsQueryKeys.loadableRobots(partnerIds, robotsAttemptedToOpen),
    queryFn: () => getLoadableRobots({ operationsApi, deliveriesApi, logEvent, partnerIds, robotsAttemptedToOpen }),
    refetchInterval: 10 * 1000,
    ...config,
  });
};
