import { v4 as uuidv4 } from 'uuid';

let loadAttemptId = '';

export const getLoadAttemptId = () => {
  if (loadAttemptId) {
    return loadAttemptId;
  }
  loadAttemptId = uuidv4();
  return loadAttemptId;
};

export const resetLoadAttemptId = () => {
  loadAttemptId = uuidv4();
};
