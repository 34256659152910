import React from 'react';

import { Delivery } from 'src/@types';
import { ORDER_PROVIDER_MAP } from 'src/constants';
import { styled } from 'src/stitches.config';
import { formatPrice } from 'src/utils';

type Props = {
  delivery: Delivery;
  handleClick: () => void;
};

const OrderItem: React.FC<Props> = ({ delivery, handleClick }) => {
  const { customerName, orderSource, cartTotal } = delivery;

  return (
    <StyledOrderItem onClick={handleClick}>
      <CustomerDetails>
        <CustomerName>{customerName}</CustomerName>
        <Platform>{ORDER_PROVIDER_MAP[orderSource] ?? ''}</Platform>
      </CustomerDetails>
      {cartTotal !== 0 && <CartTotal>{formatPrice(cartTotal)}</CartTotal>}
    </StyledOrderItem>
  );
};

export default OrderItem;

const StyledOrderItem = styled('div', {
  display: 'flex',
  alignItems: 'baseline',
  justifyContent: 'space-between',
  padding: '20px 25px',
  border: '1px solid #DCDCDC',
  borderRadius: '$lg',
  marginBottom: '10px',
  boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
});

const CustomerDetails = styled('div');

const Text = styled('p', {
  margin: '0',
});

const CustomerName = styled(Text, {
  fontWeight: '700',
  fontSize: '16px',
  lineHeight: '133.4%',
  color: '#000',
});

const Platform = styled(Text, {
  paddingTop: '10px',
  fontWeight: '600',
  fontSize: '14px',
  lineHeight: '143%',
  color: '#000',
  opacity: '0.6',
  letterSpacing: '0.15px',
});

const CartTotal = styled('div', {
  fontWeight: '600',
  fontSize: '16px',
  lineHeight: '133.4%',
  color: '#000',
});
