import { styled } from '../../stitches.config';

const Button = styled('button', {
  background: '$primary',
  width: '100%',
  maxWidth: '500px',
  height: '48px',
  fontSize: '18px',
  color: 'white',
  borderRadius: '4px',
  border: 0,
  fontWeight: '$semibold',
  cursor: 'pointer',
  '&:active': {
    backgroundColor: '#3D3C3C',
  },
  '&:disabled': {
    pointerEvents: 'none',
    cursor: 'default',
    color: 'rgba(0, 0, 0, 0.26)',
    boxShadow: 'none',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
  '&.grey': {
    background: 'rgb(172 170 170 / 40%)',
    color: '#000000bd',
    '&:active': {
      backgroundColor: 'rgb(61 60 60 / 11%)',
    },
  },
});

export default Button;
