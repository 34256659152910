import { useQuery } from 'react-query';

import { useAppContext } from 'src/context';
import { deliveryQueryKeys } from '../@types/react-query';
import { useAmplitude } from '../hooks';
import { getDeliveriesByPartners } from '../services';
import { handleQueryError } from '../utils';

const useDeliveries = () => {
  const { logEvent } = useAmplitude();
  const { deliveriesApi, privacyApi, partnerIds } = useAppContext();

  return useQuery({
    queryKey: deliveryQueryKeys.list(partnerIds),
    queryFn: () => getDeliveriesByPartners(deliveriesApi, privacyApi, logEvent, partnerIds),
    onError: handleQueryError(logEvent, 'useDeliveries'),
  });
};

export default useDeliveries;
