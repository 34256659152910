import { styled } from '@stitches/react';
import { differenceInMinutes, format } from 'date-fns';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BarLoader from 'react-spinners/BarLoader';

import { EVENTS, OpenLidError } from 'src/@types';
import WarningIcon from 'src/assets/warning.png';
import { SUPPORT_PHONE_NUMBER } from 'src/constants';
import { useAppContext } from 'src/context';
import { useAmplitude, useLoadableRobots } from 'src/hooks';
import useDeliveryV3 from 'src/hooks/useDeliveryV3';
import { LOC_NS, OPEN_LID_ERRORS_KEYS, ORDERS_KEYS } from 'src/i18n/types';
import { usePersistStore } from 'src/stores';
import { getErrorCopy, MAX_RETRY_COUNT } from 'src/utils';
import { Button } from '../Button';
import { CocoError } from '../CocoError';
import { LoadableRobotsList } from './LoadableRobotsList';

interface Props {
  openLidError: OpenLidError;
  hasDesignatedRobot: boolean;
  handleRetryClick: () => void;
  handleNewRobotClick: (robotSerial: string) => void;
}

const ErrorsContainer = styled('div', {
  display: 'flex',
  height: '80svh',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  maxWidth: '500px',
  gap: '1rem',
});

const CenteredContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
});

const Copy = styled('p', {
  padding: '$4 $0',
  textAlign: 'center',
  fontSize: '$lg',
  fontWeight: '600',
  lineHeight: '1.5',
});

const ErrorCard = styled('div', {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  padding: '1rem',
  marginTop: '2rem',
  gap: '1rem',
  backgroundColor: '#f0f0f0',
  borderRadius: '8px',
});

const StyledImage = styled('img', {
  height: '36px',
});

const ErrorMessage = styled('p', {
  padding: '0.2rem',
  textAlign: 'left',
  fontSize: '1rem',
  fontWeight: '600',
  lineHeight: '1.5',
});

export const OrderOpenLidError = ({
  openLidError,
  hasDesignatedRobot,
  handleRetryClick,
  handleNewRobotClick,
}: Props) => {
  const { t } = useTranslation(LOC_NS.ORDERS);
  const { t: openLidErrorsT } = useTranslation(LOC_NS.OPEN_LID_ERRORS);
  const { logEvent } = useAmplitude();
  const {
    robotsAttemptedToOpen,
    openLidRetryMode: retryMode,
    openLidRetryCount: retryCount,
    setAutoAdvanced,
  } = usePersistStore();
  const { robotId, selectedDelivery } = useAppContext();
  const router = useRouter();
  const [autoAdvanceLoading, setAutoAdvanceLoading] = useState(false);

  const waitForReturn = useMemo(() => {
    return [OpenLidError.BOT_NOT_IN_RANGE, OpenLidError.BOT_ON_TRIP].includes(openLidError) && hasDesignatedRobot;
  }, [hasDesignatedRobot, openLidError]);

  const { data: deliveryV3 } = useDeliveryV3({
    deliveryId: selectedDelivery?.id ?? '',
    config: { enabled: waitForReturn, refetchInterval: 1000 * 15 },
  });

  const errorMessage = useMemo(() => {
    if (waitForReturn) {
      const eta = deliveryV3?.attempt?.estPickupTime;
      const showEta = !!eta && differenceInMinutes(new Date(eta), new Date()) < 30;
      return showEta
        ? openLidErrorsT(OPEN_LID_ERRORS_KEYS.SCAN_ON_RETURN_ETA, {
            robotSerial: robotId,
            eta: format(new Date(eta), 'p'),
            defaultValue: `To deliver this order, please scan and load ${robotId} when it arrives around ${eta}.`,
          })
        : openLidErrorsT(OPEN_LID_ERRORS_KEYS.SCAN_ON_RETURN, {
            robotSerial: robotId,
            defaultValue: `To deliver this order, please scan and load ${robotId} when it arrives.`,
          });
    }

    return getErrorCopy(openLidError);
  }, [openLidError, waitForReturn, deliveryV3?.attempt?.estPickupTime, openLidErrorsT, robotId]);

  const allowRetry: boolean = useMemo(() => {
    return openLidError === OpenLidError.OTHER && retryMode && retryCount < MAX_RETRY_COUNT;
  }, [openLidError, retryMode, retryCount]);

  const hasBlockingError: boolean = useMemo(() => {
    return openLidError === OpenLidError.MISSING_ROBOT_FOR_DELIVERY || waitForReturn;
  }, [openLidError, waitForReturn]);

  const { data: loadableRobots, isLoading: loadingLoadableRobots } = useLoadableRobots({
    config: {
      enabled: !hasDesignatedRobot,
    },
  });

  const onClickRobot = useCallback(
    (robotSerial: string) => {
      handleNewRobotClick(robotSerial);
      logEvent({
        eventName: EVENTS.CLICK_OPEN_DIFFERENT_ROBOT,
        params: { robotSerial, robotsAlreadyAttempted: robotsAttemptedToOpen?.join(',') },
      });
    },
    [handleNewRobotClick, logEvent, robotsAttemptedToOpen]
  );

  useEffect(() => {
    if (
      robotsAttemptedToOpen.length === 1 &&
      !!loadableRobots &&
      loadableRobots.length > 0 &&
      !allowRetry &&
      !hasDesignatedRobot
    ) {
      setAutoAdvanceLoading(true);
      logEvent({
        eventName: EVENTS.AUTO_OPENING_NEXT_ROBOT,
        params: {
          robotsAttemptedToOpen: robotsAttemptedToOpen.join(','),
          loadableRobots: loadableRobots.join(','),
          nextRobot: loadableRobots[0],
        },
      });
      setTimeout(() => {
        setAutoAdvanceLoading(false);
      }, 1000);
      onClickRobot(loadableRobots[0]);
      setAutoAdvanced(true);
    }
  }, [
    allowRetry,
    hasDesignatedRobot,
    loadableRobots,
    logEvent,
    onClickRobot,
    openLidError,
    robotsAttemptedToOpen,
    setAutoAdvanced,
  ]);

  useEffect(() => {
    if (hasDesignatedRobot) {
      logEvent({
        eventName: EVENTS.OPEN_LID_FOR_UBER_ORDER_FAILURE,
        params: {
          openLidError,
          deliveryId: selectedDelivery?.id ?? '',
          robotsAttemptedToOpen: robotsAttemptedToOpen.join(','),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasDesignatedRobot, openLidError]);

  if (loadingLoadableRobots || autoAdvanceLoading) {
    return (
      <CenteredContainer>
        <Copy>{t(ORDERS_KEYS.OPENING, 'Opening')}...</Copy>
        <BarLoader color="#F268D9" loading={true} width="50%" />
      </CenteredContainer>
    );
  }

  if (hasBlockingError) {
    return (
      <CenteredContainer>
        <CocoError errorMessage={errorMessage} />
        <Button onClick={() => router.push('/')}>{t(ORDERS_KEYS.RETURN_TO_SCAN, 'Return to Scan')}</Button>
      </CenteredContainer>
    );
  }

  return (
    <ErrorsContainer>
      <ErrorCard>
        <StyledImage src={WarningIcon.src} alt="coco-error" />
        <ErrorMessage>{errorMessage}</ErrorMessage>
      </ErrorCard>

      {allowRetry ? (
        <Button onClick={handleRetryClick}>{t(ORDERS_KEYS.RETRY_OPENING, 'Retry Opening')}</Button>
      ) : hasDesignatedRobot ? (
        <>
          <ErrorMessage>
            {t(ORDERS_KEYS.CONTACT_COCO_SUPPORT_AT, 'Please contact Coco Support at')}{' '}
            <a href={`tel:${SUPPORT_PHONE_NUMBER}`}>{SUPPORT_PHONE_NUMBER}</a>{' '}
            {t(ORDERS_KEYS.TO_REQUEST_PROVIDER_CHANGE, 'to request a change of delivery providers for this order.')}
          </ErrorMessage>
          <Button onClick={() => router.push('/')}>{t(ORDERS_KEYS.RETURN_TO_SCAN, 'Return to Scan')}</Button>
        </>
      ) : (
        <LoadableRobotsList onClickRobot={onClickRobot} loadableRobots={loadableRobots} />
      )}
    </ErrorsContainer>
  );
};
