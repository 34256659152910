export enum LOC_NS {
  COMMON = 'Common',
  ORDERS = 'Orders',
  OPEN_LID_ERRORS = 'OpenLidErrors',
}

export enum COMMON_KEYS {
  UNAUTHORIZED = 'UNAUTHORIZED',
  MISSING_PERMISSIONS = 'MISSING_PERMISSIONS',
  LOG_OUT = 'LOG_OUT',
}

export enum ORDERS_KEYS {
  CONGRATULATIONS = 'CONGRATULATIONS',
  SUCCESSFULLY_LOADED = 'SUCCESSFULLY_LOADED',
  LOAD_ANOTHER = 'LOAD_ANOTHER',
  UNABLE_TO_SCAN_QR = 'UNABLE_TO_SCAN_QR',
  ENABLE_CAMERA_PERMISSIONS = 'ENABLE_CAMERA_PERMISSIONS',
  SCAN_QR_CODE = 'SCAN_QR_CODE',
  REFRESH_ORDERS = 'REFRESH_ORDERS',
  CURRENT_ORDERS = 'CURRENT_ORDERS',
  NO_ACTIVE_ORDERS = 'NO_ACTIVE_ORDERS',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  SEARCH_FOR_AN_ORDER = 'SEARCH_FOR_AN_ORDER',
  SCAN_ANOTHER_COCO = 'SCAN_ANOTHER_COCO',
  RETURN_TO_SCAN = 'RETURN_TO_SCAN',
  VIEW_ALL_ORDERS = 'VIEW_ALL_ORDERS',
  RETRY_OPENING = 'RETRY_OPENING',
  RETRY_CLOSING = 'RETRY_CLOSING',
  READ_TO_LOAD = 'READ_TO_LOAD',
  TOTAL = 'TOTAL',
  SEND_COCO = 'SEND_COCO',
  BACK_TO_ALL_ORDERS = 'BACK_TO_ALL_ORDERS',
  OPENING = 'OPENING',
  CLOSING = 'CLOSING',
  LID = 'LID',
  ORDER_CONFIRMATION = 'ORDER_CONFIRMATION',
  YES_SEND_COCO = 'YES_SEND_COCO',
  BACK_TO_CURRENT_ORDER = 'BACK_TO_CURRENT_ORDER',
  ARE_YOU_SURE_YOU_WANT_TO_SEND = 'ARE_YOU_SURE_YOU_WANT_TO_SEND',
  "CUSTOMER'S" = "CUSTOMER'S",
  ORDER_IN = 'ORDER_IN',
  UNABLE_TO_LOAD_COCO = 'UNABLE_TO_LOAD_COCO',
  SCAN_A_COCO = 'SCAN_A_COCO',
  REVIEW_THIS_ORDER = 'REVIEW_THIS_ORDER',
  CONTACT_COCO_SUPPORT_AT = 'CONTACT_COCO_SUPPORT_AT',
  TO_REQUEST_PROVIDER_CHANGE = 'TO_REQUEST_PROVIDER_CHANGE',
  CHECK_TABLET_FOR_ETA = 'CHECK_TABLET_FOR_ETA',
  SELECT_ANOTHER_COCO = 'SELECT_ANOTHER_COCO',
  DIFFERENT_ROBOT_OPENED_UBER = 'DIFFERENT_ROBOT_OPENED_UBER {{robotSerial}}',
  DIFFERENT_ROBOT_OPENED_NON_UBER = 'DIFFERENT_ROBOT_OPENED_NON_UBER {{scannedRobotSerial}} {{openedRobotSerial}}',
  GOT_IT = 'GOT_IT',
}

export enum OPEN_LID_ERRORS_KEYS {
  SCAN_ANOTHER_COCO = 'SCAN_ANOTHER_COCO',
  OFFLINE = 'OFFLINE',
  ALREADY_ASSIGNED = 'ALREADY_ASSIGNED',
  FAILED_HEALTH_CHECK = 'FAILED_HEALTH_CHECK',
  FAILED_TO_OPEN = 'FAILED_TO_OPEN',
  UNAVAILABLE = 'UNAVAILABLE',
  OUT_OF_RANGE = 'OUT_OF_RANGE',
  MISSING_DESIGNATED_ROBOT = 'MISSING_DESIGNATED_ROBOT',
  RESERVED_FOR_DIFFERENT_ORDER = 'RESERVED_FOR_DIFFERENT_ORDER',
  PLEASE_TRY_AGAIN = 'PLEASE_TRY_AGAIN',
  SCAN_ON_RETURN = 'SCAN_ON_RETURN {{robotSerial}}',
  SCAN_ON_RETURN_ETA = 'SCAN_ON_RETURN_ETA {{robotSerial}} {{eta}}',
}
