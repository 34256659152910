export enum OP_STATE {
  viewOrderList = 'viewOrderList',
  selectedDelivery = 'selectedDelivery',
  unauthorized = 'unauthorized',
  openRobot = 'openRobot',
}

export interface RobotState {
  robotState: string;
}
