// will need to revist this if we need to support international
const NumberFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const formatPrice = (total: number): string => {
  if (!Number.isInteger(total)) return '$0.00';
  return NumberFormat.format(total / 100);
};
